@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;200;400;600&display=swap');
body {
  font-family: 'Noto Sans JP', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  
  .printable, .printable * {
    visibility: visible;
  }
  
  .printable {
    position: absolute;
    left: 0;
    top: 0;
    background-color: white;
    color: black;
  }
  
  .printable .no_print {
    display: none !important;
    visibility: hidden !important;
  }
  
  .printable {
    /* フォントとレイアウトのスタイリング */
    font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Meiryo', 'メイリオ', 'Osaka', 'MS PGothic', 'arial', sans-serif;
    max-width: 210mm; /* A4サイズの幅 */
    margin: 0 auto;
    padding: 20mm;
    box-shadow: none;
    
    /* ヘッダーのスタイリング */
    h5 {
      font-size: 24pt;
      text-align: center;
      margin-bottom: 20mm;
    }
    
    /* 本文のスタイリング */
    p, h6, div {
      font-size: 10pt;
      line-height: 1.6;
      margin: 5mm 0;
    }
    
    /* サブタイトルと金額のスタイリング */
    .subtitle {
      text-align: right;
      font-weight: bold;
    }
    
    /* 下部のアドレス情報のスタイリング */
    .address {
      text-align: right;
      margin-top: 10mm;
    }
  }
}
